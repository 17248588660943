body {
    margin: 0;
    padding: 0;
    background: #FAFAFA;
}
.formContent{
    background: #FFF;
    border-radius: 6px;
    padding: 15px;
    position: relative;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.103475), 0px 3px 3px rgba(0, 0, 0, 0.0988309), 0px 3px 4px rgba(0, 0, 0, 0.10301) !important;
    .dark & {
        background: #424242 !important;
    } 
    .form{
        // max-width: 600px;
        margin: auto;
    }
    .paddingForm{
        padding: 20px 0 20px;
    }
    &.full{
        .form{
            max-width: 94%;
            min-width: 700px;
            margin: auto;
        }
    }
    &.full100{
        .form{
            max-width: 100%;
            min-width: 700px;
            margin: auto;
        }
    }    
}

.heightBody{
    height: 300px;
}
.marginLeft10{
    margin-left: 10px;
}
.picker{
    width: 100%;
    .MuiFormControl-root{
        width: 100%;
        margin-top: 8px !important;
        margin-bottom: 30px !important;
        .MuiFormLabel-root{
            background:#FFF;
            position: absolute;
            left: 0;
            transform:translate(14px, -6px) scale(0.75);
            display: inline-block;
            z-index: 9;
            padding: 0 5px;
        }
        .MuiInputBase-root{
            display: flex;
            border: 1px solid rgba(0, 0, 0, 0.23);
            padding: 3px 14px;
            border-radius: 3px;
            margin-top: 0;
            &:before,&:after{
                display: none;
            }
        }
    }
    &:first-child{
        margin-left: 0 !important;
    }    
    &:last-child{
        margin-left: 10px;
    }
}
.buttonDownload{
    position: absolute !important;
    right: 20px;
    top: 20px;
    z-index: 1;
}
.box_content_auto{
    overflow: auto;
    height: calc( 100% - 100px);
    padding-right: 10px;
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  &::-webkit-scrollbar-thumb {
    background: #d1d1d1; 
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: #999; 
  }    
}
.autoComplete{
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.0988309);
    background: red;
}
.array-input-list{
    margin-top: 0 !important;
    label.MuiInputLabel-shrink{
        opacity: 0;
    }
    label.MuiInputLabel-outlined{
        opacity: 1;
    }
    &.create{
        ul{
            margin-top: 0;
        }
    }
}
.editor{
    height: 120px !important;
}
.gallery-figcaption .gallery-figcaption--thumbnails .caption-thumbnails ul.thumbnails-list li button.thumbnail-button{
    border: 0;
}
.previews{
    margin-left: 10px;
    // width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.sizeImage{
    max-height: 200px;
    max-width: 200px;
    img{
        max-height: 200px;
        max-width: 200px;
    }
}
.errorInput {
    padding-bottom: 10px !important;
    .MuiFormHelperText-root.Mui-error{
        position: absolute;
        bottom: -15px;
        left: 0;
    }
}
.MuiAppBar-colorSecondary {
    background-color: #079D3E !important;
}
.MuiButton-containedPrimary{
    background-color: #079D3E !important;
}

.MuiTypography-colorTextSecondary {
    color: rgba(0, 0, 0, 0.87) !important;
}

.MuiTypography-root {
	font-size: 0.9rem !important;
}
.MuiListItem-button {
	font-size: 14px !important;
    line-height: 0.5;

}
.MuiTypography-colorTextSecondary {
	font-size: 16px !important;
}
.MuiSvgIcon-root {
	font-size: 1.1rem !important;
}

.MuiListItemIcon-root {
	margin-right: 0px !important;
}

.MuiCard-root {
    overflow: visible;
}
.MuiTypography-h6 {
	font-size: 1.4rem !important;
    font-weight: bold;
}
.post_code_input {
    display: inline-flex !important;
}
.style_choice{
    align-items: center;
    .MuiSvgIcon-root {
        font-size: 1.9rem !important;
    }
}
.Radio_style_check{
    display: inline-flex !important;
    margin-left: 5px;
    margin-right: 5px;
}
.text_from_to{
  width: 120px;
  font-weight: bold
}
.GroupStyle{
    border-radius: 2px;
    border: ridge;
    padding: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.EnrolledForm{
    display: inline-flex !important;
    p {
        margin-left: 20px;
        margin-right: 20px;
        width: 150px;
        font-weight: bold;
    }
    .MuiInputBase-root{
        width: 100px;
    }
    .MuiFormControl-root{
        width: 100px;
    }
}
.EnrolledOpenForm{
    
    p {
        margin-left: 20px;
        margin-right: 20px;
        width: 150px;
        font-weight: bold;
    }
    .MuiOutlinedInput-inputMultiline{
        min-height: 80px !important
    }
}
.Radio-1{
    display: inline-flex !important;
    .MuiFormControl-root {
        width: 220px;
    }
    .amount{
        margin-top: 40px;
        font-weight: bold;

    }
}
.Radio-2{
    display: inline-flex !important;
    .MuiFormControl-root {
        width: 220px;
    }
    .amount{
        margin-top: 40px;
        font-weight: bold;

    }
}
.Radio-3{
    display: inline-flex !important;
    .MuiFormControl-root {
        width: 220px !important;
    }
    .amount{
        margin-top: 40px;
        font-weight: bold;
    }
    .SpanBetween{
        margin-top: 50px;
        margin-right: 20px;
    }

}
.Radio-Group-Fee{
    flex-direction:column !important;
    p{
        text-align: center;
        padding-top: 10px;
    }
    .MuiFormControl-root{
        margin-top: 40px;
    }
    label{
        width: 160px;
    }
    input {
        width: 200px;
    }
    .MuiInputBase-root{
        width: 200px;
    }
    label{
        .MuiFormControlLabel-label{
            font-weight: bold;
        }
    }
    .MuiRadio-colorSecondary.Mui-checked{
        color:#079D3E;
    }
    
}
.header-gear-group{
    display: inline-flex ;
    div {
        width: 250px;
        text-align: center;
        span{
            font-weight: bold;
        }
    }
}
.body-gear-group{
    display: inline-flex;
}
.target_box {
    display: inline-flex;
    margin: 10px;

}
.MuiGrid-grid-xs-4 {
    display: inline-flex; 
    margin: 20px;

}
.ql-editor {
    height: 200px !important
}
.MuiToolbar-root{
    .MuiToolbar-gutters{
        width: 1300px !important
    }
}
.dialog-copy-team{
    height: 350px !important;
}
.dialog-show-mobal-place {
    height: 450px !important;
    display: grid;
}
.dialog-reset-pass {
    height: 100px !important;
}
#react-select-2-listbox {
    height: 300px !important;
}
.css-26l3qy-menu{
    height: 300px !important;
}
.MuiFormControl-marginDense{
    width: 170px;
}
.list-page{
    .MuiPaper-rounded {
        overflow-y: auto !important;
        height: 600px !important;
    }
}
.column-チーム一覧 {
    
        .MuiButton-textSizeSmall{
            margin-left: -5px;
        }
    
}
.column-イベント一覧 {
    
    .MuiButton-textSizeSmall{
        margin-left: -5px;
    }

}
.MuiTableRow-root{
    .column-no_of_team {
        text-align: center !important;
    }
    .column-no_of_event {
        text-align: center !important;
    }

}
.MuiOutlinedInput-inputMultiline {
    overflow: auto !important;
}
.list-page { 
    .MuiPaper-rounded {
        margin-top: 0px !important;
    }
}
.note-applicants {
    background: #FFF;
    border-radius: 6px;
    padding: 10px;
    position: relative;
    //box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgb(253, 253, 253) -3px -3px 6px 1px inset;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
}
.remove_placeholder {
    span {
        //color: transparent !important;
        display:none !important;
    }
}
#paper-toolbar {
    text-align: end !important;
}
#btn-delete-admin {
    background-color: #9B2335 !important;
}
#btn-resetpass-admin {
    background-color: #E15D44 !important;
}
[role=tooltip] {
    height: 300px !important;
  }
  .team-place-form{
      display: inline-flex;
  }
  .outlined-basic {
      margin-top: 1em !important;
  }
  .MuiDialog-paperWidthSm-Genre-Sport {
    .MuiDialog-paperWidthSm{
        max-width: 900px !important;
      }
  }
  .title-modal-genre-sport {
    text-align: center !important;
  }
  .cp-tab {
      .cp-tab-cat{
        min-height: 350px !important;
        max-width: 30px !important;
        padding: unset;
      } 
      display: inline-flex !important;
  }
.cp-tab-cat-sport {
    min-height: 200px !important;
    background: #fff !important;
    border-right: solid 1px #f4f5f7 !important;
    min-width: 200px  !important;
    
    width: 30% !important; 
    flex-shrink: 0 !important;
    li {
        font-size: 1.0rem;
        margin-top: 0px;
        border-bottom:rgb(231, 223, 223) solid 1px;
    }
    .active-genre{
        font: bold;
        padding: 5px;
        color: #0da428;
        border-left:rgb(11, 237, 60) solid 5px;
        //background-color: rgb(234, 225, 225);
    }
    list-style: none;
    overflow-y: scroll !important;
    height: 500px !important;
    cursor: pointer
  }
  .dialog-list-genre-sport {
      max-height: 650px !important;
      display: grid !important;
  }
  .li-genre {
      margin-top: 5px !important;
  }
  .cp-tabpanel {
      list-style: none;
      .checkbox {
          border-radius: 1px !important;
      }
      .MuiFormControlLabel-root {
          background-color: rgb(244, 244, 244);
          border-radius: 5%;
          width: 200px;
          margin: 5px;
      }
  }
  .MuiDialog-paperWidthSm-Genre-Sport {
    .MuiButton-textSizeSmall{
        font-size: large;
    }
    .MuiButton-textPrimary {
        color: #fcf9fa;
    }
    .MuiDialogActions-root {
        background-color: #079D3E;
    }
    .MuiButton-root{
        width: -webkit-fill-available;
    }
  }
  .form-detail-billings {
      width: fit-content;
      display: flex;
      padding-right: 500px;
  }
  .detail-items-list {
      margin-left: 20px;
  }
  .form-button-list {
      display: inline-flex;
  }
  .label-search {
      margin-top: -1.5em;
  }
  .input-search-bill {
    vertical-align: baseline !important;
  }
.list-total-detail{
    display: flex;
    list-style: none;
    align-items: flex-end;
}
.list-total-detail-li{
    margin-right: 15px;
    font-size: 25px;
    font-style: bold;

}
#paper-toolbar-billing{
    text-align: end !important;
    .MuiFormControl-root{
        vertical-align: bottom !important
    }
}
.btn-billing-edit{
    margin-right: 20px !important;
}
#billing-paid-select{
    width: 100px;
}
.form-data-edit-column {
    display: inline-flex !important;
    .row-form-data-edit{
        margin: 30px !important;
        .input-search-bill{
            width: 300px !important;
        }
    }
}
.btn-save-edit-billing {
    //max-width: fit-content !important;
    width: 100px !important;
    margin-left: 500px !important;
}
.btn-generate_receipt-billing {
    //max-width: fit-content !important;
    width: 200px !important;
    margin-left: 10px !important;
    background-color: #E15D44 !important;
}
.column-team_contact_id {
    text-align: left !important;
}
.btn-remove-image {
    color:#999;
    margin-right: auto;
    margin-top: auto;
    background-color:#f1f1f1;
    border-radius: 5px;
    border-color: #f1f1f1;

}
.float-left .rounded {
    width: 50px;
    height: 50px
}
.card-header .border-left-0{
    color: rgba(96, 125, 139,1.0);
}
.card .border-0 .rounded{
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.10), 0 6px 10px 0 rgba(0, 0, 0, 0.01); 
    overflow: hidden; 
    height: 100vh;
}
.position-absolute{
    right: 0px;
}
.message-right {
    text-align: right !important;
    background-color: #079D3E;
    min-width: 42px;
    height: fit-content;
    border-radius: 15px;
    padding-right: 10px;
    display: flex;
    padding-top: 5px;
    .text-chat-message{
        color: #FFF !important;
    }
}
.message-left {
    text-align: left !important;
    background-color: #d1d1d1;
    min-width: 42px;
    height: fit-content;
    display: flex;
    border-radius: 15px;
    padding-left: 10px;
    padding-top: 5px;
}
.grid-message-right {
    //display: block;
    width: 350px;
    float: right;
}
.grid-message-left {
    //display: block;
    width: 350px;

    float: left;
}
.img-message{
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 50%;
    margin-bottom: 5px;
}
.img-message-right{
    width: 40px;
    height: 40px;
    margin-left: 10px;
    margin-bottom: 5px;
    border-radius: 50%;
}
.text-chat-message {
    width: 300px;
    overflow-wrap: break-word;
    color: #424242;
    font-size: 18px;
}
.text-chat-message-date-right{
    text-align: end;
}
.text-chat-message-date-left{
    text-align: start;
}

.top-tool-bar-billing{
    display: contents !important;
}
.export-button-billing{
    padding-top: 20px;
}
.grid-action-billing{
    display:  flex !important;
}
.form-multi-edit-team{
    display:block;
    .text-input-edit-form{
        margin-left: 20px;
        margin-top: 10px;
        width: 220px;
    }
}
.multiselect-practice-day{
    margin-top: 20px;
    margin-left: 20px;
    width: 400px;
}
.dialog-update-multi-team {
        .dialog-copy-team{
            height: 350px !important;
        }
}
.MuiDialog-paperWidthSm{
    max-width: 750px !important;
}
.list-total-detail-li{
    font-size:18px;
    width: max-content;
}
.account-info-tag{
    padding-bottom: 10px;
    margin-right: 20px;
    display: grid;
    border-radius: 2px;
    border: solid 0.5px gray;
    margin-bottom: 10px;
    max-width: 30% !important;
    p{
        text-align: center;
        font-weight: 600;
        font-size: larger;
    }
    .account-info-detail {
        margin-left: 10px;
        padding-bottom: 10px;
        display: inline-flex;
    }
    div{
        margin-left: 20px;
    }
}
.array-select-status{
    width: fit-content !important;
    min-width: 120px !important;
}

.form-update-invoice{
    margin-left: 100px !important;
}
.dialog-update-invoice{
    height: fit-content;
}
.dialog-show-message-experiencer{
    height: 320px !important;
    width: 690px !important;
}
